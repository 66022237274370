<template>
  <div class="modal-container" :style="{ textAlign }">
    <div class="modal-header text-24-800" :class="{ '__center': textAlign === 'center'}">
      <p>
        <slot name="header">
          {{title}}
        </slot>
      </p>
    </div>

    <div class="modal-close-container">
      <svg-icon
        class-name="modal-close"
        icon-name="close-light"
        @click="$emit('close')"
      />
    </div>

    <div class="modal-body">
      <slot name="body">
        {{body}}
      </slot>
    </div>

    <div v-if="footerEnabled" class="modal-footer">
      <div class="button-wrap center">
        <slot name="buttons">
          <BaseButton @click="$emit('close')">{{ $t('close') }}</BaseButton>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../buttons/BaseButton'
import SvgIcon from '@shared/components/SvgIcon'

export default {
  name: 'ModalOnOverlay',
  components: { SvgIcon, BaseButton },
  props: {
    title: String,
    body: String,
    footerEnabled: {
      type: Boolean,
      default: true,
    },
    textAlign: {
      type: String,
      default: 'left',
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 14px;
  width: 524px;
  border-radius: 20px;
  padding: 0 24px;
  background-color: #fff;
  box-shadow: 0 0 24px 0 #0000000F;
  transition: all 0.3s ease;
}

.modal-header {
  padding: 70px 0 24px;
  position: relative;
  display: flex;
  align-items: center;

  > p {
    flex: 1 0 auto;
    padding-right: 20px;
  }

  &.__center {
    padding: 20px 60px;
    height: 40px;

    > p {
      padding-right: 0;
    }

    > .modal-close-container {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}

.modal-close-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
  background-color: $color-disabled;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal-close {
  width: 14px;
  height: 14px;
  stroke: #000;
}

.modal-body {
  padding: 0 0 30px 0;
}

.modal-footer {
  border-top: 1px solid $color-line;
  padding: 24px 0 24px 0;
}

.modal-default-button {
  float: right;
}
</style>
